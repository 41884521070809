import { axios } from 'ats-recruiter/src/api/RequestClient';
import { IGetMarketingMetadataV1Response } from '@interface/marketing/getMarketingMetadataV1';
import { IGetMarketingAnalyticsV1Response } from '@interface/marketing/getMarketingAnalyticsV1';
import { IGetMarketingPluginV1Response } from '@interface/marketing/getMarketingPluginV1';
import { DEFAULT_META } from '@api/marketing/default_data';
import { SOLUTION_TYPE } from 'ui/util/solution';
import { SOLUTION } from 'ui/constants/solution/solution.constant';
import { ON_OFF } from 'ui/constants/common';

const DEFAULT_META_DATA = {
  companyName: '',
  marketingSeo: {
    title: '',
    description: '',
    keywordList: [],
    imageUrl: '',
    noIndexToggle: ON_OFF.OFF,
  },
  marketingSns: {
    title: '',
    description: '',
    imageUrl: '',
  },
  googleSearchTagContent: null,
  naverSearchTagContent: null,
};

/** 채용사이트 메타태그 정보 조회 */
export const getMarketingMetadataV1 = async (prefix: string): Promise<IGetMarketingMetadataV1Response> => {
  const regex = /^(.*)\.recruiter\.co\.kr$/;
  const match = prefix.match(regex);
  /** NOTE: hotfix11.3.3 default기업 메타데이터 테스트  */
  if (SOLUTION.JOBFLEX === SOLUTION_TYPE && match) {
    const getPrefix = match[1];
    if (DEFAULT_META[getPrefix]) {
      return DEFAULT_META[getPrefix] ?? DEFAULT_META_DATA;
    }
  }
  const { data } = await axios.get('/marketing/v1/meta-data', { headers: { prefix } });
  return data;
};

/** 채용사이트 메타태그 정보 조회 */
export const getMarketingAnalyticsV1 = async (prefix: string): Promise<IGetMarketingAnalyticsV1Response> => {
  try {
    const { data } = await axios.get('/marketing/v1/analytics', { headers: { prefix } });
    return data;
  } catch (e) {
    console.error('getMarketingAnalyticsV1= prefix : ', prefix, 'error : ', e);
    return { googleAnalyticsId: '', googleManagerCode: '' };
  }
};

/** JF 채용사이트 채용공고 상세 메타태크 정보 조회 */
export const getJFMarketingMetadataV1 = async ({
  positionSn,
  prefix,
}: {
  positionSn: number;
  prefix: string;
}): Promise<IGetMarketingMetadataV1Response> => {
  try {
    const { data } = await axios.get(`/marketing/v1/position/${positionSn}/meta-data`, { headers: { prefix } });
    return data;
  } catch (e) {
    console.error('getJFMarketingMetadataV1= prefix : ', prefix, 'error : ', e);

    return {
      companyName: '',
      marketingSeo: {
        title: '',
        description: '',
        keywordList: [],
        imageUrl: '',
        noIndexToggle: ON_OFF.OFF,
      },
      marketingSns: {
        title: '',
        description: '',
        imageUrl: '',
      },
      googleSearchTagContent: null,
      naverSearchTagContent: null,
    };
  }
};

/** 플러그인 조회 */
export const getMarketingPluginV1 = async (prefix: string): Promise<IGetMarketingPluginV1Response> => {
  try {
    const { data } = await axios.get('/marketing/v1/plugin', { headers: { prefix } });
    return data;
  } catch (e) {
    console.error(e);
    return { channelTalk: { pluginKey: '' } };
  }
};
