import { CDN_URL } from '../constants/env.constant';
import { BASE_FONT_MAP, baseFontList, BaseFontType, DEFAULT_FONT, IFont } from '../constants/font.constant';
import { IServerBrandFont } from '../interface/brand/font/getBrandFont';

export const getBaseFontUrl = (fontName: string): string => {
  return `https://${CDN_URL}/fonts/${fontName}.woff2`;
};

export const findBaseFontByServerName = (fontName: BaseFontType): IFont => {
  return baseFontList.find((font) => font.name === BASE_FONT_MAP[fontName].name) ?? DEFAULT_FONT;
};

export const convertBrandFont = (brandFontFile: IServerBrandFont): IFont => {
  return {
    type: 'USER',
    id: brandFontFile.brandFontFileSn,
    fontFamily: `font-${(brandFontFile.fontFile.fileUid ?? '').split('.')[0]}`,
    url: brandFontFile.fontFile.fileUrl ?? '',
    name: brandFontFile.title,
  };
};

export const convertBrandFontList = (brandFontFileList: IServerBrandFont[]): IFont[] => {
  return brandFontFileList.map(convertBrandFont);
};

interface ILoadFonts extends IFont {
  fontWeight?: number;
}

export const loadFonts = async (fonts: ILoadFonts[]) => {
  fonts.forEach(async (font) => {
    try {
      const url = `url(${font.url})`;
      const weight = font.fontWeight?.toString() ?? '400';

      const newFontFace = new FontFace(font.fontFamily, url, { weight, style: 'normal' });
      const loadedFont = await newFontFace.load();
      document.fonts.add(loadedFont);
    } catch (error) {
      console.error(`Failed to load font ${font.fontFamily}`, error);
    }
  });
};
