import { getMarketingPluginV1 } from '@api/marketing';
import { IGetMarketingPluginV1Response } from '@interface/marketing/getMarketingPluginV1';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Nullable } from 'ui/interface/common/common';

export const QueryKey = {
  all: ['marketing'] as const,
  get: {
    all: () => [...QueryKey.all, 'get'],
    plugin: () => [...QueryKey.get.all(), 'plugin'],
  },
};

export const useQueryGetMarketingPlugin = (
  prefix: Nullable<string>,
  enabled: boolean
): UseQueryResult<IGetMarketingPluginV1Response, AxiosError> => {
  return useQuery({
    queryKey: QueryKey.get.plugin(),
    queryFn: () => {
      if (!prefix) return;
      return getMarketingPluginV1(prefix);
    },
    enabled: !!prefix && enabled,
    staleTime: Infinity,
    retry: 0,
  });
};
