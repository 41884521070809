import classNames from 'classnames/bind';
import { ComponentPropsWithoutRef, FC } from 'react';
import style from './Paragraph.module.scss';

const cx = classNames.bind(style);

interface IProps extends ComponentPropsWithoutRef<'p'> {
  variant: 'B1' | 'B2' | 'B3' | 'B4';
  bold?: boolean;
  className?: string;
  color?: string;
}

const Paragraph: FC<IProps> = ({ children, variant, bold = false, className = '', color, style }) => {
  return (
    <p style={{ ...style, color }} className={cx('paragraph', `variant-${variant}`, bold && 'bold', className)}>
      {children}
    </p>
  );
};
export default Paragraph;
