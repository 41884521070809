import { getBaseFontUrl } from '../util/font.utils';

export const BASE_FONT = {
  PRETENDARD: 'PRETENDARD',
  NOTO_SANS_KR: 'NOTO_SANS_KR',
  NANUM_SQUARE: 'NANUM_SQUARE',
  NANUM_GOTHIC: 'NANUM_GOTHIC',
  NANUM_MYEONGJO: 'NANUM_MYEONGJO',
} as const;

export type BaseFontType = keyof typeof BASE_FONT;

export const BASE_FONT_MAP = {
  [BASE_FONT.PRETENDARD]: {
    name: 'Pretendard',
    fileName: 'Pretendard-Regular',
  },
  [BASE_FONT.NOTO_SANS_KR]: {
    name: '노토산스 KR',
    fileName: 'NotoSansKR-Regular',
  },
  [BASE_FONT.NANUM_SQUARE]: {
    name: '나눔스퀘어',
    fileName: 'NanumSquare_acR',
  },
  [BASE_FONT.NANUM_GOTHIC]: {
    name: '나눔고딕',
    fileName: 'NanumGothic',
  },
  [BASE_FONT.NANUM_MYEONGJO]: {
    name: '나눔명조',
    fileName: 'NanumMyeongjo',
  },
};

export const baseFontList: IFont[] = Object.entries(BASE_FONT_MAP).map(([key, font]) => ({
  type: 'BASE',
  id: key,
  name: font.name,
  fontFamily: font.name,
  url: getBaseFontUrl(font.fileName),
}));

export const DEFAULT_FONT = baseFontList[0];

/**
 * 커스텀 폰트
 */
export interface IFont {
  type?: 'BASE' | 'USER';
  id: string | number;
  name: string;
  fontFamily: string;
  url: string;
}
