import { useLayoutEffect } from 'react';
import { baseFontList, IFont } from 'ui/constants/font.constant';
import { getBaseFontUrl, loadFonts } from 'ui/util/font.utils';

const PRETENDARD: IFont[] = [
  { fontName: 'Pretendard-Light', fontWeight: 300 },
  { fontName: 'Pretendard-Regular', fontWeight: 400 },
  { fontName: 'Pretendard-Medium', fontWeight: 500 },
  { fontName: 'Pretendard-Bold', fontWeight: 700 },
].map((font) => ({
  ...font,
  type: 'BASE',
  id: font.fontName,
  name: 'Pretendard',
  fontFamily: 'Pretendard',
  url: getBaseFontUrl(font.fontName),
}));

const useApplyStaticFont = () => {
  useLayoutEffect(() => {
    loadFonts([...baseFontList, ...PRETENDARD]);
  }, []);
};

export default useApplyStaticFont;
